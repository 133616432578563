import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import Icon from '../../../helpers/icon';
import PromoCard from './PromotionsCard';
import promotionsIcons from './promotions-icons';
import styles from './promotions.module.scss';

let position = 0;

const SampleNextArrow = (props) => {
  const { className, style, onClick, data, setImage, w, h } = props;

  const onClickNext = () => {
    if (onClick !== null) {
      onClick();
    }
    if (position < data.length - 1) {
      position += 1;
      setImage(data[position].image);
    }
  };

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: h,
        width: w,
        borderRadius: '50%',
        zIndex: 100,
        // right: '-41px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.75)',
      }}
      onClick={onClickNext}
    >
      <div className={styles.nextIconItemPromo}>
        <Icon icon="chevron-right" width="30" height="30" iconSet={promotionsIcons} />
      </div>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick, data, setImage, h, w } = props;

  const onClickNext = () => {
    if (onClick !== null) {
      onClick();
    }
    if (position > 0) {
      position -= 1;
      setImage(data[position].image);
    }
  };

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        height: h,
        width: w,
        borderRadius: '50%',
        zIndex: 100,
        // left: '-34px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.75)',
      }}
      onClick={onClickNext}
    >
      <div className={styles.prevIconItemPromo}>
        <Icon icon="chevron-left" width="30" height="30" iconSet={promotionsIcons} />
      </div>
    </div>
  );
};

class AppendDots extends React.Component {
  mapSliderCards = (cardArray) =>
    cardArray.map((card) => <PromoCard key={card.node.title} {...card.node} />);

  render() {
    const {
      sliderContent,
      width,
      height,
      decImageHandler,
      incImageHandler,
      afterChange,
    } = this.props;

    const settings = {
      customPaging: (i) => <div className={styles.sliderPageItem}>{i + 1}</div>,
      dots: true,
      dotsClass: styles.slickDotsNumber,
      infinite: false,
      speed: 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: (
        <SampleNextArrow data={sliderContent} w={width} h={height} setImage={incImageHandler} />
      ),
      prevArrow: (
        <SamplePrevArrow data={sliderContent} w={width} h={height} setImage={decImageHandler} />
      ),
      afterChange,
      cssEase: 'linear',
    };

    return (
      <div className={styles.sliderContainer}>
        <Slider {...settings}>{this.mapSliderCards(sliderContent)}</Slider>
      </div>
    );
  }
}

export default AppendDots;
