
const promotionsIcon = {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M469.146 218.419c21.402 20.89 230.502 240.384 230.502 240.384 11.418 11.213 17.152 25.805 17.152 40.397s-5.734 29.184-17.152 40.294c0 0-209.101 219.597-230.502 240.384-21.402 20.89-59.904 22.323-82.688 0-22.835-22.221-24.627-53.299 0-80.589l191.846-200.090-191.846-200.090c-24.627-27.29-22.835-58.419 0-80.691 22.784-22.323 61.286-20.941 82.688 0z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'chevron-right',
        ],
        defaultCode: 58894,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 23,
        order: 1022,
        ligatures: '',
        prevSize: 32,
        code: 58894,
        name: 'chevron-right',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 22,
    },
    {
      icon: {
        paths: [
          'M637.542 218.419c22.835 22.323 24.627 53.402 0 80.691l-191.846 200.090 191.846 200.090c24.627 27.29 22.835 58.419 0 80.589-22.784 22.323-61.286 20.89-82.688 0-21.402-20.787-230.502-240.384-230.502-240.384-11.418-11.11-17.152-25.702-17.152-40.294s5.734-29.184 17.152-40.397c0 0 209.101-219.494 230.502-240.384 21.402-20.941 59.904-22.323 82.688 0z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: [
          'chevron-left',
        ],
        defaultCode: 58893,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 22,
        order: 1021,
        ligatures: '',
        prevSize: 32,
        code: 58893,
        name: 'chevron-left',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 21,
    },

  ],
};

export default promotionsIcon;
