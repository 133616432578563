import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Odin } from '../src/components/Odin/Odin';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Loki from '../src/components/Loki/lokipane';
import Hod from '../src/components/Hod/Hod';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import SEO from '../src/helpers/seo';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import Astrid from '../src/components/Astrid/Astrid';
import Gerd from '../src/components/Gerd/Gerd';
import Promotions from '../src/components/Auto/Promotions/Promotions';

import './business.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query business($locale: String!) {
    allBusinessSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allBusinessTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allBusinessImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allBussinessPromotionSublock(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          title
          description
          cta1
          noRel1
          ctaText1
          cta2
          ctaText2
          noRel2
        }
      }
    }
    allBusinessBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allBusinessPromotionalBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
          ctaText
          cta
        }
      }
    }
    allBusinessPromotionalImageBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          image_alt
        }
      }
    }
    allBusinessContentBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allBusinessImagesBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allBusinessRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Business extends React.Component {
  state = {};

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allBusinessTitleBlock.edges[0].node.title,
      subtitle: this.props.data.allBusinessTitleBlock.edges[0].node.description,
      image: {
        url: this.props.data.allBusinessImageBlock.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allBusinessImageBlock.edges[0].node.alt,
      },
      map: 'pdf',
      mapLink: tt(
        'https://www.portaventuraevents.com/tour-virtual/',
        this.props.pageContext.locale
      ),
    };

    // const odinData = {
    //   image: this.props.data.allBusinessPromotionalImageBlock.edges[0].node.image,
    //   alt: this.props.data.allBusinessPromotionalImageBlock.edges[0].node.image_alt,
    //   title: this.props.data.allBusinessPromotionalBlock.edges[0].node.title,
    //   description: this.props.data.allBusinessPromotionalBlock.edges[0].node.body,
    //   buttons: {
    //     cta1: this.props.data.allBusinessPromotionalBlock.edges[0].node.cta,
    //     ctaText1: this.props.data.allBusinessPromotionalBlock.edges[0].node.ctaText,
    //     size: 'alone',
    //     color1: 'white',
    //   },
    // };

    const contentData = {
      title: this.props.data.allBusinessContentBlock.edges[0].node.title,
      body: this.props.data.allBusinessContentBlock.edges[0].node.body,
    };

    const lokiData = {
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allBusinessImagesBlock.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),

      bigArrayImg: this.props.data.allBusinessImagesBlock.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter((item) => item.img !== null),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allBusinessSeoBlock.edges[0].node._0.title}
          description={this.props.data.allBusinessSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allBusinessRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allBusinessImageBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall
            pageName={this.props.pageContext.pageName}
            data={heimdallData}
            handleMap={this.handleMap}
          />
          <div className="general-index">
            <div className="business-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allBusinessBreadcrumbBlock.edges[0].node.name}
              />
              <Astrid
                pageContext={this.props.pageContext}
                handleMap={this.handleMap}
                modal={this.state.showModal}
              />
              {/* <Odin data={odinData} /> */}
              <Promotions
                panelData={this.props.data.allBussinessPromotionSublock.edges}
                locale={this.props.pageContext.locale}
              />
              <H3 data={contentData} />
              <div
                className="general-container"
                dangerouslySetInnerHTML={{ __html: contentData.body }}
              />
              <div className="businessVideoContainer">
                <iframe
                  title="Business&Events"
                  className="businessVideo"
                  src="https://www.youtube.com/embed/7EUiYFSbZKM"
                />
              </div>
              <Loki data={lokiData} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Business;
