import React from 'react';
import PropTypes from 'prop-types';
import Buttons from '../../Buttons/Buttons';
import styles from './promotions.module.scss';

const PromotionsCard = (props) => (
  <div className={styles.flexContainerd}>
    <div className={styles.textContainer}>
      <h3
        className={styles.sliderTitle}
        style={{ color: `${props.colorTitle ? props.colorTitle : '#5e5e5e'}` }}
      >
        {props.title}
      </h3>
      {/* <p
        className="slider-description"
        style={{ color: `${props.colorDescription ? props.colorDescription : '#5e5e5e'}` }}
      >
        {props.description}
      </p> */}
      <div
        className="slider-description"
        style={{ color: `${props.colorDescription ? props.colorDescription : '#5e5e5e'}` }}
        dangerouslySetInnerHTML={{ __html: props.description }}
      />
    </div>
    <div className={styles.sliderLinks}>
      <Buttons
        link={props.cta1}
        noRel={props.noRel1}
        size="small"
        color="white"
        text={props.ctaText1}
      />
      {props.cta2 ? (
        <Buttons
          link={props.cta2}
          noRel={props.noRel2}
          size="small"
          color="orange"
          text={props.ctaText2}
        />
      ) : null}
    </div>
  </div>
);

PromotionsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cta1: PropTypes.string,
  noRel1: PropTypes.string,
  cta2: PropTypes.string,
  ctaText1: PropTypes.string,
  ctaText2: PropTypes.string,
  noRel2: PropTypes.string,
};

PromotionsCard.defaultProps = {
  title: 'ADELÁNTATE A LA NUEVA TEMPORADA Y COMPRA TUS ENTRADAS COMBINADAS',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing  elit. Aenean sit amet lacus eros. Donec suscipit tellus ullamcorper convallis facilisis.',
  cta1: 'https://www.startpage.com',
  cta2: 'https://www.startpage.com',
  ctaText1: 'VER PROMO',
  ctaText2: 'COMPRAR ENTRADAS',
};

export default PromotionsCard;
