import React, { Component } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './promotions.module.scss';
import AppendDots from './Slider';
import PromoCard from './PromotionsCard';
import buildFunnelUrl from '../../../helpers/buildFunnelUrl';

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: 0,
      imgMobile: 0,
    };
    this.incImageHandler = this.incImageHandler.bind(this);
    this.decImageHandler = this.decImageHandler.bind(this);
  }

  mapSliderCards = (cardArray) =>
    cardArray.map((card) => <PromoCard key={card.node.title} {...card.node} />);

  incImageHandler = () => {
    const { img } = this.state;
    this.setState({ img: img + 1 });
  };

  decImageHandler = () => {
    const { img } = this.state;
    this.setState({ img: img - 1 });
  };

  incImageHandlerMobile = () => {
    const { imgMobile } = this.state;
    this.setState({ imgMobile: imgMobile + 1 });
  };

  decImageHandlerMobile = () => {
    const { imgMobile } = this.state;
    this.setState({ imgMobile: imgMobile - 1 });
  };

  afterChange = (index) => {
    this.setState({ img: index });
  };

  afterChangeMobile = (index) => {
    this.setState({ imgMobile: index });
  };

  render() {
    const { panelData, locale } = this.props;
    const { img, imgMobile } = this.state;

    return (
      <div className={styles.promotionContainers}>
        <div className={styles.imgContainerDesktop}>
          <a
            target="_blank"
            href={buildFunnelUrl(locale, 'hotels')}
            className="t-clickable-image"
            rel="noreferrer"
          >
            <Img
              className={styles.imgFullwidth}
              fluid={panelData[img].node.localImage.childImageSharp.fluid}
              alt={panelData[img].node.alt}
            />
          </a>
        </div>
        <div className={styles.imgContainerMobile}>
          <a
            target="_blank"
            href={buildFunnelUrl(locale, 'hotels')}
            className="t-clickable-image"
            rel="noreferrer"
          >
            <Img
              className={styles.imgFullwidth}
              fluid={panelData[imgMobile].node.localImage.childImageSharp.fluid}
              alt={panelData[imgMobile].node.alt}
            />
          </a>
        </div>
        <div className={styles.sliderCont}>
          <div className={styles.promotionsMobie}>
            <AppendDots
              afterChange={this.afterChangeMobile}
              incImageHandler={this.incImageHandlerMobile}
              decImageHandler={this.decImageHandlerMobile}
              width="20px"
              height="20px"
              sliderContent={panelData}
            />
          </div>
          <div className={styles.promotionsDesktop}>
            <AppendDots
              afterChange={this.afterChange}
              incImageHandler={this.incImageHandler}
              decImageHandler={this.decImageHandler}
              width="30px"
              height="30px"
              sliderContent={panelData}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Promotions;
